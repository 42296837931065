<template>
  <iframe
    frameborder="0"
    :srcdoc="content"
    width="100%"
    scrolling="no"
  ></iframe>
</template>

<script>
export default {
  name: "RichContent",
  props: {
    content: {
      type: String,
      require: true,
      default: "",
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.setIframeHeight();
  },
  methods: {
    setIframeHeight() {
      setTimeout(() => {
        const ifr = this.$el;
        const height = ifr.contentDocument.documentElement.scrollHeight + 20;
        ifr.height = height;
      }, 200);
    },
  },
  watch: {
    content(val) {
      this.setIframeHeight();
    },
  },
};
</script>

<style lang='scss' scoped>
iframe {
  overflow: hidden;
}
</style>
<style lang="scss" scoped>
.small-nav-bar-container {
  height: 60px;
  // background:#f0f0f0;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 20px;
  // display: flex;
  .nav-btn {
    height: 60px;
    width: 60px;
    background: #dc2725;
    color: #fff;
    text-align: center;
    line-height: 60px;
    font-size: 24px;
    cursor: pointer;
  }
  .logo-container {
    flex: 1;
    height: 100%;
    line-height: 60px;
    .logo {
      margin-top: 15px;
      margin-left: 15px;
      height: 40px;
    }
  }
}
</style>

<template>
  <div class="small-nav-bar-container">
    <div class="nav-btn" @click="showDrawer = true">
      <i class="el-icon-menu"></i>
    </div>
    <div class="logo-container">
      <img src="../assets/images/newlogin.png" class="logo" />
    </div>
    <el-drawer
      :with-header="false"
      direction="ltr"
      :visible.sync="showDrawer"
      :size="150"
    >
      <el-menu>
        <template v-for="item in menuList">
          <el-submenu
            v-if="item.childrens && item.childrens.length > 0"
            :key="item.naviId"
            :index="item.naviId"
          >
            <template slot="title">
              <span>{{ item.naviName }}</span>
            </template>
            <el-menu-item
              v-for="subItem in item.childrens"
              :key="subItem.naviId"
              :index="subItem.naviId"
              @click="handleMenuClick(subItem)"
              >{{ subItem.naviName }}</el-menu-item
            >
          </el-submenu>
          <el-menu-item
            v-else
            :key="item.naviId"
            :index="item.naviId"
            @click="handleMenuClick(item)"
            >{{ item.naviName }}</el-menu-item
          >
        </template>
      </el-menu>
    </el-drawer>
  </div>
</template>

<script>
export default {
  props: {
    menuList: {
      default: () => [],
    },
  },
  data() {
    return {
      showDrawer: false,
    };
  },
  methods: {
    handleMenuClick(item) {
      if (item.childrens && item.childrens.length > 0) return;
      this.$emit("on-menu", item);
      this.showDrawer = false;
    },
  },
};
</script>
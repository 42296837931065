import Vue from "vue";
import App from "./App.vue";
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import ElementUI from "element-ui";
import router from "./router";
import store from "./store";
import scroll from "vue-seamless-scroll";
import { richContent, pageBread } from "@/components/index.js";

import "lib-flexible/flexible";
import "@/assets/css/index.scss"; // global css
import "element-ui/lib/theme-chalk/index.css";
import axios from "@/utils/request";
/* import VConsole from 'vconsole'

const vConsole = new VConsole()
Vue.use(vConsole) */

Vue.use(ElementUI);
Vue.use(scroll);
Vue.use(richContent);
Vue.use(pageBread);
Vue.use(Viewer)
Viewer.setDefaults({
  navbar: true, //底部缩略图
  toolbar: true, //底部工具栏
  button: true, //右上角按钮
  title: false, //当前图片标题
  movable: true, //是否可以移动
  zoomable: true, //是否可以缩放
  transition: true, //使用 CSS3 过度
  fullscreen: true, 
})
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.prototype.$eventBus = new Vue();
// 图片路径的处理
Vue.prototype.newBaseurl = window.$config.baseUrl;

Vue.prototype.$smallScreen = window.innerWidth < 768;
window.addEventListener("resize", () => {
  Vue.prototype.$smallScreen = window.innerWidth < 768;
});

Vue.prototype.handleNoImg = function(e) {
  let img = event.srcElement;
  if (e === "big") {
    img.src = require("./assets/images/noImg3.jpg");
  } else {
    img.src = require("./assets/images/noImg2.jpg");
  }
  img.onerror = null;
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

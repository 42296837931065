import Vue from 'vue'
import Vuex from 'vuex'
import caseDetail from './modules/caseDetail'
import reportDetail from './modules/reportDetail'
import auditReportDetail from './modules/auditReportDetail'
import workBriefingDetail from './modules/workBriefingDetail'
import regulationDetail from './modules/regulationDetail'
import managementDetail from './modules/managementDetail'
import indexDetail from './modules/indexDetail'
import classicHomeDetail from './modules/classicHomeDetail'
import navBar from './modules/navBar'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
	  caseDetail,
    reportDetail,
    auditReportDetail,
    workBriefingDetail,
    regulationDetail,
    managementDetail,
    indexDetail,
    classicHomeDetail,
    navBar
  }
})

export default {
  state: {
    storeRoute: '/',
    activeTitle: '',
    currentRoute: ''
  },
  
  mutations: {
    updateRoute (state, data) {
      state.storeRoute = data.params
    },
    updateActiveTitle (state, data) {
      state.activeTitle = data.params
    },
    updateCurrentRoute (state, data) {
      state.currentRoute = data.params
    }
  },
  actions: {
    setRoute ({ commit }, params) {
      commit({type: 'updateRoute', params})
    },
    setActiveTitle ({ commit }, params) {
      commit({type: 'updateActiveTitle', params})
    },
    setCurrentRoute ({ commit }, params) {
      commit({type: 'updateCurrentRoute', params})
    }
  }
}

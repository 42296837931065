<template>
  <div class="home-container">
    <!-- 多媒体库 -->
    <div class="block-title-wrap media-wrap">
      <div class="block-title">多媒体库</div>
      <div class="media-lib">
        <el-row
          type="flex"
          class="tab-container"
          :justify="$smallScreen ? 'center' : 'end'"
        >
          <el-radio-group v-model="mediaNavIndex">
            <el-radio-button
              class="mediaNavBtn"
              @click.native="handleGoList(item.label)"
              @mouseover.native="mediaContent(item.label)"
              :label="item.label"
              v-for="item in mediaNav"
              :key="item.label"
              >{{ item.title }}</el-radio-button
            >
          </el-radio-group>
        </el-row>
      </div>
      <div class="lib-item">
        <el-row :gutter="16">
          <template v-if="mediaNavIndex === 0">
            <el-col
              v-for="(item, index) in mediaLibs"
              :key="index"
              :span="6"
              :xs="12"
            >
              <div class="video-wrap">
                <img
                  class="pic"
                  src="@/assets/images/play.png"
                  alt=""
                  width="50"
                  @click="handleVideoPlay"
                />
                <video v-if="item.vedioPath">
                  <source
                    :src="newBaseurl + item.vedioPath"
                    class="videoPlay"
                  />
                </video>
                <!-- <h5 class="lib-title">{{ item.imageTitle }}</h5> -->
              </div>
            </el-col>
          </template>
          <template v-if="mediaNavIndex === 1">
            <el-col
              v-for="(item, index) in mediaLibs"
              :key="index"
              :span="6"
              :xs="12"
            >
              <div class="video-wrap">
                <el-image
                  v-if="item.imagePath"
                  class="imgPlay"
                  :src="newBaseurl + item.imagePath"
                  @error="handleNoImg()"
                  :preview-src-list="
                    mediaLibs.map((item) => newBaseurl + item.imagePath)
                  ">
                </el-image>
                <!-- <h5 class="lib-title">{{ item.imageTitle }}</h5> -->
              </div>
            </el-col>
          </template>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 多媒体库导航
      mediaNav: [
        { label: 0, title: "视频库" },
        { label: 1, title: "图片库" },
      ],
      // 多媒体库
      mediaLibs: [],
      mediaNavIndex: 0,
      mediaImgs: [],
    };
  },
  mounted() {
    this.getMediaImg(0);
    this.lafMediaImg();
  },
  methods: {
    // 多媒体接口 虞
    getMediaImg(id) {
      this.mediaLibs = [];
      this.$axios
        .post("/laf/laf/lafMedia/tableList.ajax", {
          pageNo: "1",
          pageSize: "8",
          condition: `mediaClassify:S_IN${id}||`,
        })
        .then((response) => {
          this.mediaLibs = response.rows.slice(0, 4);
        })
        .catch(function (error) {});
    },
    // 视频播放
    handleVideoPlay(e) {
      const target = e.target;
      target.style.display = "none";
      const videWrap = target.closest(".video-wrap");
      // const title = videWrap.querySelector(".lib-title");
      // title.style.display = "none";
      const video = videWrap.querySelector("video");
      video.controls = "controls";
      video.play();
      video.addEventListener("ended", function (e) {
        target.style.display = "block";
        // title.style.display = "block";
        video.controls = null;
      });
    },
    // 多媒体库鼠标移入
    mediaContent(label) {
      this.mediaNavIndex = label;
      this.getMediaImg(label);
    },

    // 点击视频库 跳转到视频库列表
    lafMediaImg() {
      this.$axios
        .post("/laf/laf/lafMedia/tableList.ajax", {
          pageNo: "1",
          pageSize: "8",
          condition: "mediaClassify:S_IN0||",
        })
        .then((response) => {
          this.mediaImgs = response.rows;
        })
        .catch(function (error) {});
    },

    handleGoList(label) {
      if (!this.$smallScreen) {
        let path;
        if (label === 0) {
          path = "/mediaLib/videoLib";
        } else if (label === 1) {
          path = "/mediaLib/photoLib";
        }
        this.$router.push(path);
      } else {
        this.mediaContent(label);
      }
    },
  },
};
</script>

<style lang='scss' scoped>
// 多媒体库
.media-wrap {
  .tab-container {
    @include apply-small {
      margin-top: 20px;
    }
  }
  .media-lib {
    height: 40px;
    margin-bottom: 20px;
    ::v-deep .el-radio-group {
      float: right;
      margin-right: 15px;
    }
    ::v-deep
      .el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
      box-shadow: none;
    }
    ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      background-color: #dc2725 !important;
      box-shadow: -1px 0px 9px 0px #222222 !important;
      border-color: #ccc !important;
    }
    ::v-deep.el-radio-button__inner {
      width: 170px;
      height: 40px;
      line-height: 15px;
      font-size: 18px;
    }
    .mediaNavBtn {
      color: #333333;
    }
  }
  ::v-deep .el-image__inner {
    width: 100%;
    height: 220px;
  }
  .lib-item {
    margin-top: 15px;
    position: relative;
    height: 220px;
    .video-wrap {
      position: relative;
      // width: 285px;
      height: 220px;
      // margin-right: 15px;
      margin-bottom: 20px;

      .lib-title {
        position: absolute;
        bottom: 0;
        width: 100%;
        line-height: 40px;
        height: 40px;
        background: rgba($color: #000000, $alpha: 0.4);
        // border-radius: 5px;
        color: #fff;
        text-align: center;
      }
    }
    video {
      width: 100%;
      height: 220px;
      border-radius: 5px;
      background-color: rgba($color: #000000, $alpha: 0.8);
    }
    .pic {
      position: absolute;
      margin-top: 80px;
      margin-left: 110px;
      cursor: pointer;
      z-index: 99;
      @include apply-small {
        position: absolute;
        margin-top: 80px;
        margin-left: 90px;
      }
    }

    .imgPlay {
      width: 100%;
      height: 220px;
    }
    .videoPlay {
      height: 220px !important;
    }
  }
}
</style>
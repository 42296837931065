<template>
  <div>
    <carousel />
    <notice />
    <news />
    <welfare-project />
    <classic-case />
    <multi-media />
    <support />
    <friendly-link />
    <sidebar />
    <div v-for="(item,idx) in list" :key="idx">
        <FloatingWindow :item="item"  :width="400"></FloatingWindow>
    </div>
 
  </div>
</template>

<script>
// import img1 from "../../assets/images/cys.jpg";
import img1 from "../../assets/images/floatWindowPoster.jpg";
import FloatingWindow from '@/components/FloatingWindow'
import carousel from "./components/carousel";
import notice from "./components/notice";
import news from "./components/news";
import welfareProject from "./components/welfareProject";
import classicCase from "./components/classicCase";
import multiMedia from "./components/multiMedia";
import support from "./components/support.vue";
import friendlyLink from "./components/friendlyLink.vue";
import Sidebar from "./components/sidebar.vue";
export default {
  data() {
    return {
      list:[
         {
          id: 1,
          image: img1,
        },
      ],
    };
  },

  mounted() {},
  methods: {},
  components: {
    carousel,
    notice,
    news,
    welfareProject,
    classicCase,
    multiMedia,
    support,
    friendlyLink,
    Sidebar,
    FloatingWindow
  },
};
</script>

<style scoped lang="scss">
</style>

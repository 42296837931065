import richContent from "./richContent";
import pageBread from "./pageBread";

const components = [richContent, pageBread];

components.forEach((component) => {
  component.install = function(Vue) {
    Vue.component(component.name, component);
  };
});

export { richContent, pageBread };

<template>
  <div class="home-container">
    <!-- 文字滚动 -->
    <div class="home-notice">
      <div class="left-wrap">
        <span class="notice-title">最新公告</span>
        <span class="triangle"></span>
      </div>
      <div class="right-wrap" @click="handleDetail($event)">
        <vue-seamless-scroll
          :data="noticeInfos"
          class="warp"
          :class-option="{ direction: 2, limitMoveNum: 3 }"
        >
          <ul class="notice-container">
            <li
              class="content"
              v-for="(item, index) in noticeInfos"
              :key="index"
            >
              <p class="text" :data-idx="index">{{ item.announceTitle }}</p>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
    <!-- <noticeDetail
      :visible.sync="innerVisible"
      @close="innerVisible = false"
      :formData="formData"
    /> -->
  </div>
</template>
<script>
// import noticeDetail from "@/components/noticeDetail";
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  data() {
    return {
      formData: {
        info: "",
      },
      innerVisible: false,
      // 通知公告内容
      noticeInfos: [],
      pageNo: 1,
      pageSize: 10,
      condition: "|",
      total: 0,
    };
  },
  mounted() {
    // this.handleGetList();
    this.newNotice();
  },
  methods: {
    // 点击显示详情
    handleDetail(e) {
      if (e.target.tagName == "P") {
        let i = e.target.dataset.idx;
        let info = this.noticeInfos[i];
        this.innerVisible = true;
        this.formData = info;
        const pkValue = info.row_pkValue;
        window.open(`./#/indexDetail?pkValue=${pkValue}`, "_blank");
      }
    },

    newNotice() {
      this.$axios({
        method: "post",
        url: "/laf/laf/lafAnnouncement/tableList.ajax",
        data: {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          condition: this.condition,
          orderField: "pubDtm",
          orderCondition: "desc",
          _entityName: "org.aweto.laf.entity.LafAnnouncement",
        },
      })
        .then((res) => {
          this.total = res.totalRows;
          this.noticeInfos = res.rows;
          console.log(res);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  components: {
    vueSeamlessScroll,
    // noticeDetail,
  },
};
</script>

<style lang="scss" scoped>
// 文字滚动
.home-notice {
  height: 70px;
  line-height: 70px;
  font-size: 18px;
  color: #777777;
  margin-top: 10px;
  background-color: #f7f8fa;
  display: flex;
  .left-wrap {
    width: 170px;
    height: 100%;
    .notice-title {
      display: inline-block;
      width: 130px;
      background-color: #dc2725;
      color: #fff;
      // font-size: 24px;
      text-align: center;
    }

    .triangle {
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-top-width: 0.6rem;
      border-bottom-width: 0.6rem;
      border-left-width: 0.15rem;
      border-right-width: 0.15rem;
      border-color: #dc2725 transparent transparent #dc2725;
      vertical-align: top;
    }
  }
  .right-wrap {
    flex: 1;
    height: 100%;
    overflow: hidden;
  }
  .warp {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    cursor: pointer;
    overflow: hidden;
    ul {
      list-style: none;
      padding: 0;
      margin: 0 auto;
      &.notice-container {
        display: flex;
        .content {
          width: 343px;
          height: 100%;
          margin-right: 40px;
        }
      }
    }
  }
  .text:hover {
    color: $red;
  }
}
</style>
export default {
  state: {
    caseObj: {}
  },
  actions: {
    setCaseDetail ({ commit }, params) {
	  commit({type: 'updateDetail', params})
    }
  },
  mutations: {
    updateDetail (state, data) {
      state.caseObj = data.params
    }
  }
}

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: Home,
  },

  {
    path: "/home/report",
    name: "homeReport",
    component: () => import("@/views/home/report.vue"),
  },
  {
    path: "/home/list",
    name: "homeList",
    component: () => import("@/views/home/list.vue"),
  },

  {
    path: "/about",
    name: "About",
    redirect: "/about/introduce",
    component: () => import("@/views/about/index.vue"),
    children: [
      {
        path: "introduce",
        name: "Introduce",
        component: () => import("@/views/about/introduce.vue"),
      },
      {
        path: "constitution",
        name: "constitution",
        component: () => import("@/views/about/constitution.vue"),
      },
      {
        path: "structure",
        name: "structure",
        component: () => import("@/views/about/structure.vue"),
      },
      {
        path: "council",
        name: "council",
        component: () => import("@/views/about/council.vue"),
      },
      {
        path: "chairman",
        name: "chairman",
        component: () => import("@/views/about/chairman.vue"),
      },
      {
        path: "contact",
        name: "contact",
        component: () => import("@/views/about/contact.vue"),
      },
    ],
  },

  // 党建工作
  {
    path: "/partyBuild",
    name: "partyBuild",
    redirect: "/partyBuild/partyStudy",
    component: () => import("@/views/partyBuild/index.vue"),
    children: [
      {
        path: "/partyBuild/partyStudy",
        redirect: "/partyBuild/partyStudy/1"
      },
      {
        path: "partyStudy/:page",
        name: "partyStudy",
        component: () => import("@/views/partyBuild/partyStudy.vue"),
      },
      {
        path: "/partyBuild/partyBrand",
        redirect: "/partyBuild/partyBrand/1"
      },
      {
        path: "partyBrand/:page",
        name: "partyBrand",
        component: () => import("@/views/partyBuild/partyBrand.vue"),
      },
      {
        path: "/partyBuild/organization",
        redirect: "/partyBuild/organization/1"
      },
      {
        path: "organization/:page",
        name: "organization",
        component: () => import("@/views/partyBuild/organization.vue"),
      },
    ],
  },

  //党建工作详情页
  {
    path: "/partyBuild/partyDetail",
    name: "/partyBuild/partyDetail",
    component: () => import("@/views/partyBuild/partyDetail.vue"),
  },

  // 时讯新闻
  {
    path: "/news",
    name: "news",
    redirect: "/news/currentNews",
    component: () => import("@/views/news/index.vue"),
    children: [
      {
        path: "/news/currentNews",
        redirect: "/news/currentNews/1"
      },
      {
        path: "currentNews/:page",
        name: "currentNews",
        component: () => import("@/views/news/currentNews.vue"),
      },
      {
        path: "/news/judicial",
        redirect: "/news/judicial/1"
      },
      {
        path: "judicial/:page",
        name: "judicial",
        component: () => import("@/views/news/judicial.vue"),
      },
      {
        path: "/news/dynamic",
        redirect: "/news/dynamic/1"
      },
      {
        path: "dynamic/:page",
        name: "dynamic",
        component: () => import("@/views/news/dynamic.vue"),
      },
    ],
  },
  //时讯新闻详情页
  {
    path: "/news/detail",
    name: "newsDetail",
    component: () => import("@/views/news/detail.vue"),
  },
  {
    path: "/welfareProject/detail",
    name: "welfareProjectDetail",
    component: () => import("@/views/welfareProject/projectDynamic/detail.vue"),
  },
  {
    path: "/welfareProject/weflDetail",
    name: "welfareProjectWeflDetail",
    component: () =>
      import("@/views/welfareProject/projectDynamic/weflDetail.vue"),
  },
  // 公益项目
  {
    path: "/welfareProject/:id",
    name: "welfareProject",
    redirect: "/welfareProject/:id/projectIntroduce",
    component: () => import("@/views/welfareProject/index.vue"),
    children: [
      {
        path: "projectIntroduce",
        name: "projectIntroduce",
        component: () =>
          import(
            "@/views/welfareProject/projectIntroduce/projectIntroduce.vue"
          ),
      },
      {
        path: "projectDynamic/:page/:keyWord?",
        name: "projectDynamic",
        component: () =>
          import("@/views/welfareProject/projectDynamic/projectDynamic.vue"),
      },
      {
        path: "projectResult",
        name: "projectResult",
        component: () =>
          import("@/views/welfareProject/projectResult/projectResult.vue"),
      },
    ],
  },

  // 爱心捐赠
  {
    path: "/donation",
    name: "donation",
    redirect: "/donation/online",
    component: () => import("@/views/donation/index.vue"),
    children: [
      {
        path: "online",
        name: "online",
        component: () => import("@/views/donation/online.vue"),
      },
      {
        path: "online/:openId",
        name: "online2",
        component: () => import("@/views/donation/online.vue"),
      },
      {
        path: "offline",
        name: "offline",
        component: () => import("@/views/donation/offline.vue"),
      },
      {
        path: "query",
        name: "query",
        component: () => import("@/views/donation/query.vue"),
      },
      {
        path: "enterprise", //企业
        name: "enterprise",
        component: () => import("@/views/donation/enterprise.vue"),
      },
      {
        path: "individual", //个人
        name: "individual",
        component: () => import("@/views/donation/individual.vue"),
      },
    ],
  },
  //  信息公开
  {
    path: "/information",
    name: "information",
    redirect: "/information/workReport",
    component: () => import("@/views/information/index.vue"),
    children: [
      {
        path: "/information/workReport",
        redirect: "/information/workReport/1"
      },
      {
        path: "workReport/:page",
        name: "workReport",
        component: () => import("@/views/information/workReport.vue"),
      },
      {
        path: "/information/auditReport",
        redirect: "/information/auditReport/1"
      },
      {
        path: "auditReport/:page",
        name: "auditReport",
        component: () => import("@/views/information/auditReport.vue"),
      },
      {
        path: "/information/workBriefing",
        redirect: "/information/workBriefing/1"
      },
      {
        path: "workBriefing/:page", //工作简报
        name: "workBriefing",
        component: () => import("@/views/information/workBriefing.vue"),
      },
      {
        path: "/information/serviceInformation",
        redirect: "/information/serviceInformation/1"
      },
      {
        path: "serviceInformation/:page", //业务信息
        name: "serviceInformation",
        component: () => import("@/views/information/serviceInformation.vue"),
      },
    ],
  },
  // 法律文件
  {
    path: "/lawFiles",
    name: "lawFiles",
    redirect: "/lawFiles/regulations",
    component: () => import("@/views/lawFiles/index.vue"),
    children: [
      {
        path: "regulations",
        redirect: "/lawFiles/regulations/1"
      },
      {
        path: "regulations/:page",
        name: "regulations",
        component: () => import("@/views/lawFiles/regulations.vue"),
      },
      {
        path: "management",
        redirect: "/lawFiles/management/1"
      },
      {
        path: "management/:page",
        name: "management",
        component: () => import("@/views/lawFiles/management.vue"),
      },
      {
        path: "file",
        name: "file",
        component: () => import("@/views/lawFiles/file.vue"),
      },
    ],
  },

  // 多媒体库
  {
    path: "/mediaLib",
    name: "mediaLib",
    redirect: "/mediaLib/videoLib",
    component: () => import("@/views/mediaLib/index.vue"),
    children: [
      {
        path: "videoLib",
        name: "videoLib",
        component: () => import("@/views/mediaLib/videoLib.vue"),
      },
      {
        path: "photoLib",
        name: "photoLib",
        component: () => import("@/views/mediaLib/photoLib.vue"),
      },
    ],
  },
  //首页公益项目详情----有用不要删
  {
    path: "/caseDetail",
    name: "caseDetail",
    component: () =>
      import("@/views/welfareProject/projectDynamic/caseDetail.vue"),
  },
  // 搜索页
  {
    path: "/search",
    name: "search",
    component: () => import("@/views/welfareProject/search.vue"),
  },
  // 通知公告
  {
    path: "/notice",
    redirect: "/notice/1"
  },
  {
    path: "/notice/:page",
    name: "notice",
    component: () => import("@/views/notice/index.vue"),
  },
  // 工作简报详情
  {
    path: "/workBriefingDetail",
    name: "workBriefingDetail",
    component: () => import("@/views/information/workBriefingDetail.vue"),
  },
  // 审计报告详情
  {
    path: "/auditReportDetail",
    name: "auditReportDetail",
    component: () => import("@/views/information/auditReportDetail.vue"),
  },
  // 工作报告详情
  {
    path: "/reportDetail",
    name: "reportDetail",
    component: () => import("@/views/information/reportDetail.vue"),
  },
   // 业务信息详情
   {
    path: "/serviceInformationDetail",
    name: "serviceInformationDetail",
    component: () => import("@/views/information/serviceInformationDetail.vue"),
  },
  // 通知公告详情
  {
    path: "/indexDetail",
    name: "indexDetail",
    component: () => import("@/views/notice/indexDetail.vue"),
  },
  
  // 法律法规详情
  {
    path: "/regulationDetail",
    name: "regulationDetail",
    component: () => import("@/views/lawFiles/regulationDetail.vue"),
  },

  // 综合管理详情
  {
    path: "/managementDetail",
    name: "managementDetail",
    component: () => import("@/views/lawFiles/managementDetail.vue"),
  },
  // 典型案例详情
  {
    path: "/classicHomeDetail",
    name: "classicHomeDetail",
    component: () => import("@/views/lawFiles/classicHomeDetail.vue"),
  },
  // 搜索详情
  {
    path: "searchDetail",
    name: "searchDetail",
    component: () => import("@/views/welfareProject/searchDetail.vue"),
  },
  {
    path: "/classicCaseDetail",
    name: "classicCaseDetail",
    component: () => import("@/views/home/components/classicCaseDetail.vue"),
  },
  // 典型案例
  {
    path: "/typicalCase",
    name: "typicalCase",
    component: () => import("@/views/typicalCase/index.vue"),
  },
  // 典型案例详情 wf  不要删代码有用
  {
    path: "/typicalCase/caseDetail",
    name: "/typicalCase/caseDetail",
    component: () => import("@/views/typicalCase/caseDetail.vue"),
  },
];
const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;

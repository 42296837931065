<template>
  <div class="home-header">
    <div class="home-container">
      <div class="headerTitle">
        江苏省法律援助基金会欢迎您!
        <!-- 江苏省法律援助基金会欢迎您! -->
        <el-dropdown class="avatar-container" v-if="name">
          <div class="avatar-wrapper">
            <!-- <img class="user-avatar" :src="avatar"> -->
            <el-avatar
              :size="30"
              class="user-avatar"
              src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
            ></el-avatar>
            {{ name }}
            <i class="el-icon-caret-bottom"></i>
          </div>
          <el-dropdown-menu class="user-dropdown" slot="dropdown">
            <el-dropdown-item>
              <span @click="logout" style="display: block">退出</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="login-wrap" v-else>
          <a @click="(visible = true), (isLogin = true)">登录</a> |
          <a @click="(visible = true), (isLogin = false)">注册</a>
        </div>
      </div>
    </div>

    <!-- 登录弹窗 -->
    <el-dialog
      :visible.sync="visible"
      :show-close="false"
      class="login-dialog"
      destroy-on-close
      @close="dialogClose"
    >
      <!-- 登录  -->
      <template v-if="isLogin">
        <div slot="title" class="dialog-title">
          用户登录 <i class="el-icon-close" @click="handleClose"></i>
        </div>
        <el-form
          key="login"
          :model="loginForm"
          :rules="loginRules"
          ref="loginForm"
          class="dialog-form"
        >
          <el-form-item prop="phone">
            <el-input
              placeholder="请输入手机号"
              v-model="loginForm.phone"
              maxlength="11"
            >
              <i slot="prefix" class="el-icon-mobile-phone">&nbsp;|</i>
            </el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input placeholder="请输入验证码" v-model="loginForm.code">
              <i slot="prefix" class="el-icon-lock">&nbsp;|</i>
              <span slot="suffix">
                |
                <a class="blue" @click="getCode" v-if="isCanGet">获取验证码</a>
                <span v-else>{{ currentTime }} S</span>
              </span>
            </el-input>
          </el-form-item>
          <div class="form-tip">
            没有帐号，<a class="blue" @click="openRegister">去注册</a>
          </div>
        </el-form>
        <template slot="footer">
          <el-button type="primary" @click="handleLogin">立即登录</el-button>
        </template>
      </template>

      <!-- 注册 -->
      <template v-else>
        <div slot="title" class="dialog-title">
          用户注册 <i class="el-icon-close" @click="handleClose" />
        </div>
        <el-form
          key="reg"
          :model="regForm"
          :rules="regRules"
          ref="regForm"
          class="dialog-form"
        >
          <el-form-item prop="userName">
            <el-input placeholder="请输入昵称" v-model="regForm.userName">
              <i slot="prefix" class="el-icon-user">&nbsp;|</i>
            </el-input>
          </el-form-item>
          <el-form-item prop="userPhone">
            <el-input
              placeholder="请输入手机号"
              v-model="regForm.userPhone"
              maxlength="11"
            >
              <i slot="prefix" class="el-icon-mobile-phone">&nbsp;|</i>
            </el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input placeholder="请输入验证码" v-model="regForm.code">
              <i slot="prefix" class="el-icon-lock">&nbsp;|</i>
              <span slot="suffix">
                |
                <a class="blue" @click="getCode" v-if="isCanGet">获取验证码</a>
                <span v-else>{{ currentTime }} S</span>
              </span>
            </el-input>
          </el-form-item>
          <div class="form-tip">
            已有帐号，<a class="blue" @click="openLogin">去登录</a>
          </div>
        </el-form>
        <template slot="footer">
          <el-button type="primary" @click="handleReg">立即注册</el-button>
        </template>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  data() {
    const token = sessionStorage.getItem("token");
    const name = token && sessionStorage.getItem("name");
    return {
      name: name,
      isLogin: true,
      visible: false,
      isCanGet: true,
      currentTime: 60,
      loginForm: {
        phone: "",
        code: "",
      },
      loginRules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^\d{11}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },

      regForm: {
        userName: "",
        userPhone: "",
        code: "",
      },
      regRules: {
        userName: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        userPhone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^\d{11}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },

      // 页面去哪里
      toPath: "",
    };
  },
  mounted() {
    this.$eventBus.$on("openLogin", (data) => {
      const { toPath } = data;
      this.visible = true;
      this.toPath = toPath;
    });
  },
  methods: {
    logout() {
      sessionStorage.removeItem("name");
      sessionStorage.removeItem("token");
      this.name = undefined;
      this.$router.push("/home");
    },

    async getCode() {
      const form = this.isLogin ? "loginForm" : "regForm";
      const field = this.isLogin ? "phone" : "userPhone";
      this.$refs[form].validateField(field, async (err) => {
        if (err) return false;
        this.isCanGet = false;
        this.timer = setInterval(() => {
          this.currentTime--;
          if (this.currentTime <= 0) {
            this.resetGetCode();
          }
        }, 1000);
        const res = await this.$axios.get("/laf/SMS/getSMS.ajax", {
          params: {
            phone: this.isLogin ? this.loginForm.phone : this.regForm.userPhone,
          },
        });
      });
    },

    // 登陆
    handleLogin() {
      this.$refs.loginForm.validate(async (valid) => {
        if (!valid) return false;
        const res = await this.$axios.post(
          "/laf/SMS/login.ajax",
          this.loginForm
        );
        if (res.code === "1000") {
          this.visible = false;
          this.name = res.name;
          res.name && sessionStorage.setItem("name", res.name);
          res.token && sessionStorage.setItem("token", res.token);
          this.$notify({
            title: "成功",
            message: "登陆成功",
            type: "success",
          });
          if (this.toPath) {
            this.$router.push(this.toPath);
          }
        } else {
          this.$notify({
            title: "失败",
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    // 注册
    handleReg() {
      this.$refs.regForm.validate(async (valid) => {
        if (!valid) return false;
        const res = await this.$axios.post(
          "/laf/SMS/register.ajax",
          this.regForm
        );
        if (res.code === "1000") {
          this.visible = false;
          this.name = res.name;
          res.name && sessionStorage.setItem("name", res.name);
          document.cookie = `name=${res.name}`;

          res.token && sessionStorage.setItem("token", res.token);

          this.$notify({
            title: "成功",
            message: "注册成功",
            type: "success",
          });
          if (this.toPath) {
            this.$router.push(this.toPath);
          }
        } else {
          this.$notify({
            title: "失败",
            message: res.msg,
            type: "error",
          });
        }
      });
    },

    dialogClose() {
      this.$refs.loginForm && this.$refs.loginForm.resetFields();
      this.$refs.regForm && this.$refs.regForm.resetFields();

      this.resetGetCode();
    },

    handleClose() {
      this.visible = false;
    },

    // 去登陆
    openLogin() {
      this.isLogin = true;
      this.resetGetCode();
    },
    // 去注册
    openRegister() {
      this.isLogin = false;
      this.resetGetCode();
    },

    resetGetCode() {
      clearInterval(this.timer);
      this.currentTime = 60;
      this.isCanGet = true;
    },
  },
};
</script>

<style lang='scss' scoped>
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.home-header {
  height: 40px;
  background-color: #fbf9f9;
  line-height: 40px;
  font-size: 14px;
  color: #666;
  padding: 0 20px;
}
.headerTitle {
  padding-left: 50px;
}

.login-wrap {
  float: right;
}

.navbar {
  line-height: 65px;
}
.navbar-logo {
  color: #1b50b6;
  font-size: 28px;
}
.avatar-container {
  float: right;
  .avatar-wrapper {
    cursor: pointer;
    position: relative;
  }
  .user-avatar {
    vertical-align: -10px;
  }
}
.user-dropdown {
  width: 100px;
  margin-top: -5px;
  padding: 0;
}

.login-dialog {
  .dialog-title {
    text-align: center;
    color: $red;
    font-size: 18px;
    .el-icon-close {
      float: right;
      font-size: 22px;
      color: #bbb;
      margin-top: 6px;
    }
  }
  ::v-deep .el-dialog {
    width: 500px;
  }
  ::v-deep .el-dialog__body {
    padding-bottom: 0;
  }
  .dialog-form {
    .blue {
      color: #3e8af6;
    }
    ::v-deep .el-input__inner {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
    }
    .form-tip {
      text-align: right;
    }
  }
  .el-button--primary {
    width: 100%;
    background-color: $red;
    border-color: $red;
  }
}
</style>
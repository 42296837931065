<style lang="scss" scoped>
.service-modal ::v-deep.el-dialog {
  border-radius: 5px;
  overflow: hidden;
  width: 700px;
}
.service-modal ::v-deep.el-tabs__nav-scroll {
  padding-left: 50px;
}
.service-modal ::v-deep.el-tabs__active-bar {
  background-color: black;
}
.service-modal ::v-deep.el-dialog__header {
  padding: 0px 0px 0px;
}
.service-modal ::v-deep.el-dialog__body {
  padding: 0;
}
.service-modal ::v-deep.el-dialog__headerbtn {
  top: 16px;
  width: 30px;
  height: 30px;
  border: 1px solid #fff;
  border-radius: 5px;
}
.service-modal ::v-deep.el-dialog__headerbtn:hover .el-dialog__close {
  color: #dc2725;
}
.service-modal ::v-deep.el-dialog__headerbtn .el-dialog__close {
  color: #fff;
}

.service-header {
  width: 100%;
  height: 60px;
  background: url(../assets/images/title2.png) no-repeat;
  background-size: cover;
  padding-top: 1px;
  user-select: none;
  .main-title {
    margin-top: 6px;
    font-size: 20px;
    color: #fff;
    letter-spacing: 2px;
    text-indent: 20px;
  }
  .sub-title {
    font-size: 12px;
    color: #fff;
    letter-spacing: 1px;
    text-indent: 20px;
  }
}
.main-content {
  width: 450px;
  height: 450px;
  float: left;
  border-right: 1px solid #ddd;
}
.right-panel {
  width: 250px;
  height: 450px;
  margin-left: 450px;
  ::v-deep .el-tabs__nav-wrap::after {
    display: none;
  }
  ::v-deep.el-tabs__item.is-active,
  ::v-deep.el-tabs__item:hover {
    color: initial;
  }
  .menu-tab {
    text-align: center;
    .menu {
      height: 80px;
      width: 80px;
      cursor: pointer;
    }
  }
  .problem-tab {
    .problem-item {
      color: #333;
      font-size: 12px;
      margin-bottom: 4px;
      cursor: pointer;
      text-indent: 10px;
      height: 24px;
      line-height: 24px;
    }
    .problem-item:hover {
      background: #f8f8f8;
    }
  }
}
.main-content .chat-container {
  width: 100%;
  height: 330px;
  background: #f8f8f8;
  border-bottom: 1px solid #ddd;
  overflow: auto;
  box-sizing: border-box;
  padding: 0 10px;
  .time-container {
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: center;
    .time {
      font-size: 12px;
      background: #e1e1e1;
      color: #fff;
      padding: 2px 4px;
    }
  }
  &::-webkit-scrollbar {
    width: 10px;
    height: 1px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #fff;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #ededed;
  }
}
.chat-info {
  padding-top: 20px;
  margin-top: 8px;
  &::after {
    content: "";
    display: block;
    clear: both;
  }
  &.left .chat-media,
  &.left .chat-content {
    float: left;
  }
  &.left .chat-content {
    margin-left: 15px;
  }
  &.left .chat-content::after {
    left: -10px;
  }
  &.left .chat-name {
    left: 0;
  }
  &.right .chat-media,
  &.right .chat-content {
    float: right;
  }
  &.right .chat-content {
    margin-right: 15px;
  }
  &.right .chat-content::after {
    right: -10px;
  }
  &.right .chat-name {
    right: 0;
  }
  .chat-media {
    width: 60px;
    text-align: center;
    .userimg {
      width: 50px;
      height: 50px;
    }
  }
  .chat-content {
    max-width: 250px;
    background-color: #ffffff;
    box-shadow: 0px 0px 21px 0px rgba(26, 29, 46, 0.08);
    border-radius: 8px;
    position: relative;
    padding: 10px;
    &::after {
      content: "";
      display: block;
      height: 0;
      width: 0;
      position: absolute;
      top: 0;
      border-top: 14px solid transparent;
      border-bottom: 14px solid transparent;
      border-left: 14px solid transparent;
      border-right: 14px solid #fff;
      transform: rotate(-90deg);
    }
    .chat-name {
      position: absolute;
      color: #ccc;
      font-size: 14px;
      top: -24px;
    }
    .problem-item {
      color: #008cee;
      font-size: 12px;
      margin-bottom: 4px;
      cursor: pointer;
      text-indent: 10px;
      height: 24px;
      line-height: 24px;
    }
    .problem-item:hover {
      text-decoration: underline;
    }
  }
}
.chat-input ::v-deep.el-textarea__inner {
  border: none;
  padding-top: 10px;
  &::-webkit-scrollbar {
    width: 10px;
    height: 1px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #fff;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #ededed;
  }
}
.service-modal .chat-input ::v-deep.el-textarea__inner::placeholder {
  color: #757575;
  font-size: 14px;
}
.main-content .send-container {
  width: 100%;
  height: 120px;
  position: relative;
  .send-btn {
    position: absolute;
    right: 10px;
    bottom: 4px;
    height: 30px;
    line-height: 28px;
    padding: 0 20px;
  }
}
</style>

<template>
  <!---在线客服---->
  <el-dialog
    v-bind="$attrs"
    class="service-modal"
    @opened="handleOpened"
    v-on="$listeners"
    append-to-body
  >
    <div class="service-header">
      <h3 class="main-title">江苏省法律援助基金会智能客服小雅</h3>
      <h4 class="sub-title">优质服务 源于至心了解</h4>
    </div>
    <div class="main-content">
      <div ref="chatCon" class="chat-container">
        <div class="time-container" v-show="showGreet">
          <span class="time">{{ firstTime }}</span>
        </div>
        <div v-show="showGreet" class="chat-info left">
          <div class="chat-media">
            <img src="../assets/images/header.png" class="userimg" />
          </div>
          <div class="chat-content">
            <div class="chat-name">小雅</div>
            <div class="content">
              您好，我是江苏省法律援助基金会的智能客服小雅，很高兴为您服务~
            </div>
          </div>
        </div>
        <div v-show="showProblems" class="chat-info left">
          <div class="chat-media">
            <img src="../assets/images/header.png" class="userimg" />
          </div>
          <div class="chat-content">
            <div class="chat-name">小雅</div>
            <div class="content">
              <div
                class="problem-item"
                v-for="(item, idx) in normalProblem"
                :key="'np' + idx"
                @click="handleProblemClick(item)"
              >
                {{ idx + 1 }}．{{ item }}
              </div>
            </div>
          </div>
        </div>
        <div v-for="(item, idx) in chatList" :key="'ch' + idx">
          <div class="time-container">
            <span class="time">{{ item.time }}</span>
          </div>
          <div
            class="chat-info"
            :class="[item.type === 'answer' ? 'left' : 'right']"
          >
            <div class="chat-media">
              <img
                :src="item.type === 'answer' ? robotImg : constmImg"
                class="userimg"
              />
            </div>
            <div class="chat-content">
              <div class="chat-name">{{ item.name }}</div>
              <div class="content">{{ item.content }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="send-container">
        <el-input
          type="textarea"
          :rows="3"
          placeholder="请输入您要咨询的问题"
          resize="none"
          class="chat-input"
          show-word-limit
          :maxlength="200"
          v-model="problem"
        >
        </el-input>
        <el-button
          class="send-btn"
          size="small"
          round
          @click="handleProblemClick(problem, 1)"
          >发送</el-button
        >
      </div>
    </div>
    <div class="right-panel">
      <el-tabs v-model="activeName">
        <el-tab-pane label="快捷窗口" name="menu-tab" class="menu-tab">
          <img
            class="menu"
            src="@/assets/images/linetop1.png"
            alt="线上捐助"
            @click="handleLineTop"
          />
          <img
            class="menu"
            style="margin-left: 20px"
            src="@/assets/images/linebottom1.png"
            alt="线下捐助"
            @click="handleLinBottom"
          />
        </el-tab-pane>
        <el-tab-pane label="常见问题" name="problem-tab" class="problem-tab">
          <div
            class="problem-item"
            v-for="(item, idx) in normalProblem"
            :key="'np' + idx"
            @click="handleProblemClick(item)"
          >
            {{ idx + 1 }}．{{ item }}
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-dialog>
</template>

<script>
import moment from "moment";
moment.locale("zh-cn");
export default {
  data() {
    return {
      activeName: "problem-tab",
      problem: "",
      normalProblem: [],
      chatList: [],
      isFirstLoad: false,
      showGreet: false,
      showProblems: false,
      firstTime: "",
      robotImg: require("../assets/images/header.png"),
      constmImg: require("../assets/images/people.png"),
      robotName: "小雅",
    };
  },
  created() {
    this.getNomarlProblem();
  },
  methods: {
    //线上捐款
    handleLineTop() {
      this.$router.push("/donation/online");
    },
    //线下捐款
    handleLinBottom() {
      this.$router.push("/donation/offline");
    },
    handleOpened() {
      if (!this.isFirstLoad) {
        this.firstTime = moment().format("lll");
        this.isFirstLoad = true;
        setTimeout(() => {
          this.showGreet = true;
        }, 200);
        setTimeout(() => {
          this.showProblems = true;
        }, 400);
      }
    },
    handleProblemClick(problem, type = 2) {
      this.chatList.push({
        type: "problem",
        time: moment().format("lll"),
        content: problem,
        name: "游客",
      });
      if (type === 1) this.problem = "";
      this.chatToBottom();
      // this.$refs.chatCon.sty
      this.getAnswer(problem, type).then((res) => {
        if (res && res.length > 0) {
          res.forEach((item) => {
            this.chatList.push({
              type: "answer",
              time: moment().format("lll"),
              content: item.REP_ANSWER,
              name: this.robotName,
            });
          });
        } else {
          this.chatList.push({
            type: "answer",
            time: moment().format("lll"),
            content: "我还不能明白您的意思~",
            name: this.robotName,
          });
        }
        this.chatToBottom();
      });
    },
    // 获取问题列表
    getNomarlProblem() {
      this.$axios({
        method: "post",
        url: "/laf/laf/lafReply/query.ajax",
        params: {
          question: "",
          type: 3,
        },
      }).then((res) => {
        this.normalProblem = res ? res.map((item) => item.REP_QUESTION) : [];
      });
    },
    // 获取答案
    getAnswer(p, type = 1) {
      return this.$axios({
        method: "post",
        url: "/laf/laf/lafReply/query.ajax",
        params: {
          question: p,
          type,
        },
      });
    },
    chatToBottom() {
      setTimeout(() => {
        this.$refs.chatCon.scrollTop = this.$refs.chatCon.scrollHeight - 300;
      }, 200);
    },
  },
};
</script>
<template>
  <div class="home-container">
    <!-- 友情链接 -->
    <div class="block-title-wrap">
      <div class="block-title">友情链接</div>
      <div class="link-wrap">
        <el-row
          type="flex"
          class="Friendship-link"
          :justify="$smallScreen ? 'center' : 'end'"
        >
          <el-radio-group v-model="linksNavIndex">
            <el-radio-button
              @mouseover.native="handleLinksNav(item.label)"
              :label="item.label"
              v-for="item in linksNavTabs"
              :key="item.label"
              >{{ item.title }}</el-radio-button
            >
          </el-radio-group>
        </el-row>
        <div class="links-img">
          <vue-seamless-scroll
            :data="governments"
            class="warp"
            :class-option="{ direction: 2, limitMoveNum: 3 }"
          >
            <ul class="notice-container">
              <li
                class="content"
                v-for="(item, index) in governments"
                :key="index"
                @click="handleDetail(item)"
              >
                <a :href="item.linkUrl" target="_blank">
                  <img
                    :src="newBaseurl + item.linkPath"
                    alt=""
                    class="linkLogin"
                  />
                </a>
              </li>
            </ul>
          </vue-seamless-scroll>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "friendlyLink",
  data() {
    return {
      // 友情链接导航
      linksNavTabs: [
        { label: "01", title: "政府部门" },
        { label: "02", title: "基金会" },
        { label: "03", title: "合作单位" },
      ],
      linksNavIndex: "01",
      // 友情链接图片数组
      governments: [],
    };
  },
  mounted() {
    this.lafLink("01");
  },
  methods: {
    // 友情链接图片
    //  鼠标经过
    handleLinksNav(label) {
      this.lafLink(label);
      this.linksNavIndex = label;
    },

    // 友情链接
    lafLink(id) {
      this.$axios
        .post("/laf/laf/lafLink/tableList.ajax", {
          pageSize: "10",
          pageNo: "-1",
          condition: `organClas:S_IN${id}||`,
        })
        .then((response) => {
          console.log(response);
          this.governments = response.rows;

          console.log(this.governments);
        })
        .catch(function (error) {});
    },
  },
};
</script>

<style lang='scss' scoped>
// 友情链接
.link-wrap {
  .links-img {
    img {
      display: inline-block;
      width: 285px;
      height: 100px;
      margin-bottom: 15px;
      object-fit: contain;
    }
  }
  .Friendship-link {
    @include apply-small {
      margin-top: 20px;
    }
    height: 40px;
    margin-bottom: 20px;
    ::v-deep .el-radio-group {
      float: right;
      margin-right: 15px;
    }
    ::v-deep
      .el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
      box-shadow: none;
    }
    ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      background-color: #dc2725 !important;
      box-shadow: -1px 0px 9px 0px #222222 !important;
      border-color: #ccc !important;
    }
    ::v-deep .el-radio-button__inner {
      width: 170px;
      height: 40px;
      line-height: 14px;
      font-size: 18px;
    }
  }
}
.warp {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    &.notice-container {
      display: flex;
      .content {
        width: 285px;
        height: 100%;
        margin-right: 23px;
      }
    }
  }
  // .linkLogin {
  //   border: 1px solid #e0e0e0;
  //   object-fit: cover;
  //   border-collapse: collapse;
  // }
}
</style>
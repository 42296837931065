<template>
  <!-- 轮播图 -->
  <el-carousel
    :height="bannerHeight + 'px'"
    arrow="always"
    indicator-position="none"
  >
    <!-- <el-carousel-item v-for="(item, index) in carouselList" :key="index">
      <img class="carousel-img" :src="newBaseurl + item" /> -->

    <!-- <div
        class="carouselImg"
        :style="`background-image: url(${newBaseurl}${item});background-size:cover`"
      ></div> -->
    <!-- <img :src="`${newBaseurl}`{item}" width="100%" /> -->
    <!-- </el-carousel-item> -->

    <el-carousel-item v-for="(item, index) in carouselList" :key="index">
      <img
        :src="newBaseurl + item"
        ref="bannerHeight"
        class="carousel-img"
        @load="imgLoad"
      />
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  data() {
    return {
      carouselList: [],
      bannerHeight: "",
    };
  },
  created() {},
  mounted() {
    this.getLafCarousel();
    this.imgLoad();
    // this.getMoveCarousel();
    window.addEventListener(
      "resize",
      () => {
        this.bannerHeight = this.$refs.bannerHeight[0].height;
        this.imgLoad();
      },
      false
    );
  },

  methods: {
    // 轮播图pc端接口
    getLafCarousel() {
      this.$axios({
        method: "get",
        url: "/laf/laf/lafCarousel/findAllImg.ajax",
        params: {
          pageNo: "1",
          condition: "-1",
          classify: `${window.innerWidth > 768 ? 1 : 2}`,
          // classify: "2",
          pageSize: "10",
        },
      })
        .then((response) => {
          this.carouselList = response;
          console.log( this.carouselList )
        })
        .catch((error) => {
          console.log(error);
        });
    },

    imgLoad() {
      this.$nextTick(() => {
        this.bannerHeight =
          this.$refs.bannerHeight && this.$refs.bannerHeight[0].height;
        // console.log(this.$refs.bannerHeight[0].height);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// 轮播图
::v-deep {
  // .el-carousel__container {
  //   height: 430px;
  // }
  .el-carousel__button {
    width: 88px;
    height: 5px;
    background-color: #fff;
  }
  .el-carousel__arrow {
    width: 50px;
    height: 50px;
  }
  .el-icon-arrow-left {
    width: 18px;
    height: 18px;
  }
}
.carousel-img {
  width: 100%;
  display: block;
  @include apply-small {
    width: 100%;
    display: block;
    height: 100%;
    object-fit: fill;
  }
}
</style>

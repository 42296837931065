<template>
  <!-- 侧边栏 -->
  <transition name="sidebar">
    <div class="sidebar" v-show="visible">
      <div class="sidebar-item" @click="handleOnline">
        <img src="@/assets/images/online.png" class="pic" />
        <p class="text">线上捐助</p>
      </div>
      <div class="sidebar-item" @click="handleDownline">
        <img src="@/assets/images/downline.png" class="pic offline" />
        <p class="text">线下捐助</p>
      </div>
      <div class="sidebar-item" @click="handleReport">
        <img src="@/assets/images/xmsb.png" class="pic" />
        <el-row class="block-col-2">
          <el-col :span="12">
            <el-dropdown>
              <span class="el-dropdown-link declaration">
                项目申报<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-plus" class="text"
                  ><span @click="handleJob">项目咨询</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
        </el-row>
      </div>
      <span class="back-top" @click="handleBackTop">
        <img class="back-icon" src="@/assets/images/TOP_icon.png" />
      </span>
      <online-service
        :visible.sync="innerVisible"
        @close="innerVisible = false"
      />
    </div>
  </transition>
</template>

<script>
import { throttle } from "lodash";
import onlineService from "@/components/onlineService";

export default {
  name: "sidebar",
  data() {
    return {
      activeName: "second",
      visible: false,
      innerVisible: false,
    };
  },
  mounted() {
    document.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    //线上捐款
    handleOnline() {
      this.$router.push("/donation/online");
      //点击线上捐款时，navBar上的菜单也要相应变更
      this.$store.dispatch('setActiveTitle','/donation/online')
      this.$store.dispatch('setRoute','/donation/online')
    },
    // 线下捐款
    handleDownline() {
      this.$router.push("/donation/offline");
      //点击线下捐款时，navBar上的菜单也要相应变更
      this.$store.dispatch('setActiveTitle','/donation/offline')
      this.$store.dispatch('setRoute','/donation/offline')
    },
    handleJob() {
      this.innerVisible = true;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // handleClick(){
    //   this.visible  =true
    // },
    dialogClose() {},
    // 返回顶部
    handleBackTop() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },

    handleReport() {
      const token = sessionStorage.getItem("token");
      if (token) {
        this.$router.push({
          path: "/home/list",
        });
      } else {
        this.$eventBus.$emit("openLogin", { toPath: "/home/list" });
      }
    },
    toOnline() {
      this.$router.push("/donation/online");
    },
    toOffline() {
      this.$router.push("/donation/offline");
    },

    // throttle 防抖节流 的处理
    handleScroll: throttle(function (e) {
      const ele = document.documentElement || document.body;
      // const eleHeight = ele.scrollHeight;
      let toTop = ele.scrollTop || window.pageYOffset;
      if (toTop > 275 && toTop < document.body.scrollHeight - 458) {
        this.visible = true;
      } else {
        this.visible = false;
      }
    }, 100),
  },
  // 组件销毁前  解除事件(该事件注册在全局)，防止继续触发该事件
  beforeDestroy() {
    document.removeEventListener("scroll", this.handleScroll);
  },
  components: {
    onlineService,
  },
};
</script>

<style lang='scss' scoped>
.sidebar-enter,
.sidebar-leave-to {
  opacity: 0;
}
.sidebar-enter-active,
.sidebar-leave-active {
  transition: all 0.4s ease;
}
// 侧边栏
.sidebar {
  position: fixed;
  top: 230px;
  right: 30px;
  z-index: 999;
  .sidebar-item {
    width: 100px;
    height: 100px;
    background: #c81b38;
    border-radius: 50%;
    margin-bottom: 21px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  a {
    text-decoration: none;
  }
  .text {
    color: #fff;
    font-size: 14px;
    margin-top: 5px;
  }
  // @include apply-small {
  //   .text {
  //     color: #fff;
  //     font-size: 12px;
  //     margin-top: 5px;
  //   }
  // }
  .declaration {
    white-space: nowrap;
  }
  ::v-deep .el-icon-plus:before {
    content: 0;
  }
  ::v-deep .el-icon-arrow-down:before {
    content: none;
  }
  .pic {
    width: 30px;
    height: 30px;
    &.offline {
      width: 40px;
    }
  }
  ::v-deep .el-dropdown {
    color: #fff !important;
  }
  .back-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .back-icon {
      width: 60px;
    }
  }
}
</style>
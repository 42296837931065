<template>
  <div class="home-container">
    <div class="block-title-wrap support">
      <div class="block-title">参与支持</div>
      <div class="pic">
        <a><img @click="toOnline" src="@/assets/images/jzck.png" /></a>
        <a><img @click="toQuery" src="@/assets/images/jzcx.png" /></a>
      </div>
    </div>
  </div>
</template> 

<script>
export default {
  name: "support",
  data() {
    return {
      activeIndex: "0",
    };
  },
  mounted() {},
  methods: {
    // 点击捐赠窗口 跳转到 在线捐赠
    toOnline() {
      this.$router.push("/donation/online");
    },
    // 点击捐赠查询 跳转到 捐赠查询
    toQuery() {
      this.$router.push("/donation/query");
    },
  },
};
</script>

<style lang='scss' scoped>
.home-container{
  @include apply-big{
    display: none;
  }
}
.support {
  .pic{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    text-align: center;
    line-height: 80px;
  }
}
</style>
<template>
  <div>
    <img class="image" src="@/assets/images/star3.png" alt="" />
    <img class="image1" src="@/assets/images/star2.jpg" alt="" />
    <el-breadcrumb separator=">">
      <div class="home-container">
        <span class="tip">您的位置：</span>
        <el-breadcrumb-item v-for="item in breadList" :key="item">{{
          item
        }}</el-breadcrumb-item>
      </div>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: "pageBread",
  data() {
    return {
      breadList: [],
    };
  },

  mounted() {
    this.getBreadList();
  },
  methods: {
    getBreadList() {
      let menuList = sessionStorage.getItem("menuList") || "";
      menuList = (menuList && JSON.parse(menuList)) || [];
      if (!menuList.length) {
        setTimeout(() => {
          this.getBreadList();
        }, 500);
        return;
      }
      // 当菜单是公益项目时，由于动态路由的影响需做特殊处理
      if (!this.$route.path.includes("/welfareProject")) {
        const { matched } = this.$route;
        const paths = matched.map((m) => m.path);
        const names = [];
        const first = menuList.find((menu) => menu.naviPath === paths[0]);
        names.push(first.naviName);
        // 最多两级
        if (paths.length > 1) {
          const second =
            first &&
            first.childrens.find((menu) => paths[1].includes(menu.naviPath));
          second && names.push(second.naviName);
        }
        this.breadList = names;
      } else {
        const names = [];
        names.push("公益项目");
        switch (this.$route.params.id) {
          case "S_IN0":
            names.push("法援案件项目");
            break;
          case "S_IN1":
            names.push("普法宣传项目");
            break;
          case "S_IN2":
            names.push("特色站点项目");
            break;
          case "S_IN3":
            names.push("高校法援项目");
            break;
          default:
            names.push("培训奖励项目");
        }
        this.breadList = names;
      }
    },
  },
  watch: {
    $route() {
      this.getBreadList();
    },
  },
};
</script>

<style lang='scss' scoped>
.image {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 25px;
  display: block;
}
.image1 {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 25px;
  display: none;
}
@include apply-small {
  .image1 {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 25px;
    display: block;
  }
  .image {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 25px;
    display: none;
  }
}
.el-breadcrumb {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 25px;
  background: #fafafa;
  ::v-deep {
    .el-breadcrumb__separator {
      color: #606266;
      font-weight: normal;
    }
  }
}
.tip {
  float: left;
}
</style>